.header {
  background:url(images/blue-gradient.jpg) center repeat-y #022f5c;width:100%;height:88px;
}

.header .container {
  position:relative;
}

.header .container .navbar-brand {
  padding:0 15px;margin:8px 0;position:relative;min-height:1px;
}
@media (
  min-width:768px
){
  .header .container .navbar-brand {
    float:left;
  }
}

.header .container .navbar-brand img {
  display:block;max-width:125px;margin:0 auto;
}

.header .container .navbar-brand .WN-BannerTextDiv {
  color:#fff;
  font-size:13px;
  font-style:italic;
  text-align:center;
  clear:left;
  width:auto;
  margin:5px 0 0;
  padding:0;
  height:auto;
  font-family:  Arial, Helvetica, sans-serif;
}

.downMsg {
  color: red;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  background-color: rgb(218,216,216);
  margin-bottom: 20px;
}

.col {
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
  
  -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
  -moz-column-gap: 40px; /* Firefox */
  column-gap: 40px;
      display: inline-block;
}
* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  }
  
a {
              text-decoration: none;
}


.controls {
  margin: auto;
  text-align: center;
  
  font-weight: bold;
      color: #022f5c;
      font-size: 18px;
      text-transform: uppercase

}
#appsList {
  width: 84%;
  margin:0 auto;
}
#CatBusFin, #CatClinPatSaf, #CatEmpRes, #CatLearn, #CatSupServ, #CateCare {
  float: left;
  padding: 15px;
  text-align: left;
      border: 1px solid #ccc;
  width:100%;
  margin-bottom: 15px;
}
.column {
  width: 25%;
  float: left;
  padding: 20px;
}
.WS1column {
      width: 45%;
      float: left;
      padding: 15px;
      }
.cat h3 {
  font-size: 20px;
  color: #022f5c;
  margin-top: 0;
}
.list div{
  margin-bottom: 10px;
}

input.main-search {
  margin-left:  10px;
  padding:6px;
}

.main-search::-ms-clear {
  display:none;
  }
  
.subCat {
  font-weight: bold;
  margin-top: 25px;
}	

.Columns {
  margin: 0 auto;
}

.appsList {
  margin: 0 auto;
  width: 85%;
}

.linkInfo {
  color: red;
  font-size: 18px;
}

@media only screen and (max-width: 1199px) {
  .column {
    padding: 10px;
  }
  .list .name {
    font-size: 14px;
  }
}
@media only screen and (max-width: 991px) {
  .column {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  #appsList {
    width: 100%;
  }
  .header {
    padding-top: 10px;
  }
  .header .container .navbar-brand {
    padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}